<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
        <template v-if="Object.keys(store).length > 0">
          <transition
          mode="out-in"
          >
          <b-col md="8">
            <b-card>
                <b-form @submit.prevent="update">
                  <b-row>
                    <!-- logo -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Store Logo')"
                        label-for="v-logo"
                      >
                        <b-img :src="store.logo" v-if="store.logo" width="120" thumbnail class="mb-1" />
                        <b-form-file
                          id="v-logo"
                          accept="image/jpeg, image/jpg, image/png, image/gif"
                          :browse-text="$t('Browser')"
                          :placeholder="$t('Upload Image')"
                          v-model="store.new_logo"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- favicon -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Store Favicon')"
                        label-for="v-favicon"
                      >
                        <b-img :src="store.favicon" v-if="store.favicon" width="50" thumbnail class="mb-1" />
                        <b-form-file
                          id="v-favicon"
                          accept="image/jpeg, image/jpg, image/png, image/gif"
                          :browse-text="$t('Browser')"
                          :placeholder="$t('Upload Image')"
                          v-model="store.new_favicon"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- name -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Store Name')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Store Name')"
                          v-model="store.name"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- url -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Store Url')"
                        label-for="v-store-url"
                        :description="$t('If you want to change store url, contact sellers service')"
                      >
                        <b-form-input
                          id="v-store-url"
                          :placeholder="$t('Store Url')"
                          v-model="store.url"
                          readonly
                        />
                      </b-form-group>
                    </b-col>

                    <!-- description -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Store Description')"
                        label-for="v-store-description"
                      >
                        <b-form-textarea
                          id="v-store-description"
                          :placeholder="$t('Store Description')"
                          v-model="store.description"
                          no-resize
                          rows="6"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- address -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Store Address')"
                        label-for="v-address"
                      >
                        <b-form-input
                          id="v-address"
                          :placeholder="$t('Store Address')"
                          v-model="store.address"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- instagram -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('Instagram Account')"
                        label-for="v-instagram-account"
                      >
                        <b-form-input
                          id="v-instagram-account"
                          :placeholder="$t('Instagram Account')"
                          v-model="store.instagram_account"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Twitter -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('Twitter Account')"
                        label-for="v-twitter-account"
                      >
                        <b-form-input
                          id="v-twitter-account"
                          :placeholder="$t('Twitter Account')"
                          v-model="store.twitter_account"
                        />
                      </b-form-group>
                    </b-col>
                    
                    <!-- Snap -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('Snap Account')"
                        label-for="v-snap-account"
                      >
                        <b-form-input
                          id="v-snap-account"
                          :placeholder="$t('Snap Account')"
                          v-model="store.snap_account"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- TikTok -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('TikTok Account')"
                        label-for="v-tiktok-account"
                      >
                        <b-form-input
                          id="v-tiktok-account"
                          :placeholder="$t('TikTok Account')"
                          v-model="store.tiktok_account"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('Email Contact')"
                        label-for="v-email-contact"
                      >
                        <b-form-input
                          id="v-email-contact"
                          :placeholder="$t('Email Contact')"
                          v-model="store.email_contact"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- mobile -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('Mobile Contact')"
                        label-for="v-mobile-contact"
                      >
                        <b-form-input
                          id="v-mobile-contact"
                          :placeholder="$t('Mobile Contact')"
                          v-model="store.mobile_contact"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- whatsapp -->
                    <b-col cols="12" md="6">
                      <b-form-group
                        :label="$t('WhatsApp Number')"
                        label-for="v-whatsapp-number"
                      >
                        <b-form-input
                          id="v-whatsapp-number"
                          :placeholder="$t('WhatsApp Number')"
                          v-model="store.whatsapp_number"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- maroof -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Maroof Url')"
                        label-for="v-maroof-url"
                      >
                        <b-form-input
                          id="v-maroof-url"
                          :placeholder="$t('Maroof Url')"
                          v-model="store.maroof_url"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- legal_name -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Company Legal Name')"
                        label-for="v-legal-name"
                      >
                        <b-form-input
                          id="v-legal-name"
                          :placeholder="$t('Company Legal Name')"
                          v-model="store.legal_name"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Commercial Registration Number -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Commercial Registration Number')"
                        label-for="v-cr-number"
                      >
                        <b-form-input
                          id="v-cr-number"
                          :placeholder="$t('Commercial Registration Number')"
                          v-model="store.cr_number"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- VAT Number -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('VAT Number')"
                        label-for="v-vat-number"
                      >
                        <b-form-input
                          id="v-vat-number"
                          :placeholder="$t('VAT Number')"
                          v-model="store.vat_number"
                        />
                      </b-form-group>
                    </b-col>


                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
        </template>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'Store-Info',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      store: {}
    }
  },
  mounted() {
    this.getStoreInfo();
  },
  methods: {
    getStoreInfo(){
        this.isLoading = true
        useJwt.post('/store/get-by-id',{id: this.$store.state.store.id})
        .then((response) => {
          // console.log(response.data)
          this.store = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
    update() {
      this.isLoadingSave = true;

      // create form data to upload images
      let form = new FormData(); 
      Object.entries(this.store).forEach(([key, value]) => {
        form.append(key, value);
      });

      useJwt.post('/store/update',form)
      .then((response) => {
        // console.log(response.data);
        this.store = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>
